import React, { useState } from 'react';
import styled from 'styled-components';
import { ASSET_ICONS } from '../../../Constants/Icons';
import { iRisk, RiskWithAssetInfo } from '../../../Global/riskReducer';
import { ColumnProps, SortState, Table } from '../../../Reusables/table/Table';
import { READONLY_STATUS } from '../../../Constants/Risks';
import { getRiskColor, getTimeMetrics } from '../../../utils/riskUtils';
import { Footer } from './Footer';
import { useHeader } from './Header';
import { Button, HStack, Primitive } from '@rtkwlf/fenrir-react';
import { RisksParams } from '../../../types/risks';
import { SOURCE_TEXT } from '../../Assets/constants';
import { CriticalityPill } from '../../Assets/AssetDetails/InfoRowRenderers/CriticalityRenderer';
import TagPills from '../../../Reusables/TagPills';
import RescanControl from '../../../Reusables/RescanControl';
import { useSelector } from 'react-redux';
import { infoPanSelectors } from '../../../InfoPanes/infoPaneReducer';

const StyledContainer = styled.div`
  display: block;
  overflow-x: auto;
  padding-bottom: 30px;
  margin-bottom: -30px;

  table thead th {
    white-space: nowrap;
  }
`;

const StyledCheckbox = styled.input`
  position: relative;
  border-color: #cccccc;
  border: 1px solid;
  border-radius: 4px;
  border-width: 1px;
`;

const StyledRowCheckbox = styled.input`
  position: relative;
  top: 2px;
  border-color: #cccccc;
  border: 1px solid;
  border-radius: 4px;
  border-width: 1px;
`;

const RiskUpdateControls = styled(HStack)`
  background-color: #eeeeee;
  border: 1px solid #cccccc;
  box-sizing: border-box;
`;

const RiskScoreRender = styled(Primitive.div)<{ $riskColor: string }>`
  width: 66px;
  border-radius: 36px;
  background: ${(props) => props.$riskColor};
`;

const getMetricsData = (risk: iRisk) => {
  return getTimeMetrics(
    risk.attributes.firstIdentified,
    risk.attributes.resolvedTime
  );
};

type Props = {
  riskArray: Array<RiskWithAssetInfo>;
  highlightList: Array<string>;
  nextPage: string;
  onSort: (column: string, sortState: SortState) => void;
  page: number | undefined;
  pageSize: number;
  rowClick: (riskId: string) => void;
  setPage: (page: number) => void;
  totalItems: number;
  selectAll: (checked: boolean) => void;
  selectRisk: (risk: iRisk, event: React.FormEvent<HTMLInputElement>) => void;
  clearAllSelected: () => void;
  updateRisks: () => void;
  onChange: <K extends keyof RisksParams>(
    key: K,
    value: RisksParams[K]
  ) => void;
  rescanBtnDisabled: boolean;
  rescanSelected: () => Promise<void>;
  rescanIsLoading: boolean;
};

const RiskTable = ({
  riskArray = [],
  highlightList,
  nextPage,
  onSort,
  page,
  pageSize,
  rowClick,
  setPage,
  totalItems,
  selectAll,
  selectRisk,
  clearAllSelected,
  updateRisks,
  onChange,
  rescanBtnDisabled,
  rescanSelected,
  rescanIsLoading,
}: Props) => {
  const { hiddenColumnKeys, header } = useHeader(pageSize, onChange);

  const [rescanModalVisibility, setRescanModalVisibility] = useState(false);

  const isRiskPaneOpen = useSelector(infoPanSelectors.getPaneIsOpen);
  const riskPanePayload = useSelector(infoPanSelectors.getSelectedRiskPayload);
  const paneRiskId =
    isRiskPaneOpen && riskPanePayload ? riskPanePayload['riskId'] : '';

  const selectedRisks = React.useMemo(() => {
    if (!highlightList.length) {
      return null;
    }

    return (
      <RiskUpdateControls
        gap='zero'
        alignItems='center'
        position='relative'
        marginTop='smedium'
        marginBottom='smedium'
        marginLeft='zero'
        marginRight='zero'
        padding='small'
        borderRadius='small'
        data-testid='selected-risk-count'
        xAlign='between'
      >
        <HStack gap='zero'>
          <Primitive.span
            marginLeft='smedium'
            marginRight='smedium'
            marginTop='zero'
          >{`${highlightList.length} item(s) selected`}</Primitive.span>
          <Button
            marginBottom='zero'
            data-testid='update-selected'
            onClick={() => updateRisks()}
            iconLeft={<i className='fa fa-refresh' />}
          >
            Update Selected
          </Button>
          <RescanControl
            isRescanDisabled={rescanBtnDisabled}
            isModalOpen={rescanModalVisibility}
            isLoading={rescanIsLoading}
            modalTitle='Rescan Risks'
            modalMessage='Are you sure you want to rescan the selected risks?'
            handleRescanClick={() => setRescanModalVisibility(true)}
            handleModalConfirm={rescanSelected}
            handleModalCancel={() => setRescanModalVisibility(false)}
          />
        </HStack>
        <Button
          marginBottom='zero'
          variant='link'
          onClick={() => clearAllSelected()}
          iconRight={<i className='fa fa-times' />}
        >
          Deselect All
        </Button>
      </RiskUpdateControls>
    );
  }, [
    highlightList,
    clearAllSelected,
    updateRisks,
    rescanSelected,
    rescanModalVisibility,
    rescanBtnDisabled,
    rescanIsLoading,
  ]);

  const columns: ColumnProps<RiskWithAssetInfo>[] = React.useMemo(() => {
    const allColumns: ColumnProps<RiskWithAssetInfo>[] = [
      {
        key: 'row-select',
        title: (
          <StyledCheckbox
            data-testid={`risk-select-all`}
            type='checkbox'
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              selectAll(e.currentTarget.checked);
            }}
            disabled={
              riskArray.filter(
                (r: iRisk) => !READONLY_STATUS.includes(r.attributes.status)
              ).length === 0
            }
            checked={
              highlightList.length > 0 &&
              highlightList.length ===
                riskArray.filter(
                  (r: iRisk) => !READONLY_STATUS.includes(r.attributes.status)
                ).length
            }
          />
        ),
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <div>
            <StyledRowCheckbox
              type='checkbox'
              disabled={READONLY_STATUS.includes(risk.attributes.status)}
              title={
                READONLY_STATUS.includes(risk.attributes.status)
                  ? 'Mitigated and Obsolete risks are readonly'
                  : ''
              }
              onClick={(e: React.FormEvent<HTMLInputElement>) => {
                selectRisk(risk, e);
                e.stopPropagation();
              }}
              readOnly
              checked={highlightList?.includes(risk.id)}
            />
          </div>
        ),
      },
      {
        key: 'source',
        title: 'Source',
        sortable: true,
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <>
            {<i className={`fa ${ASSET_ICONS[risk.source]}`} /> ||
              risk.source ||
              ''}
            {`  ${SOURCE_TEXT[risk.source] || ''}`}
          </>
        ),
      },
      {
        key: 'host',
        title: 'Host',
        sortable: true,
        justify: 'flex-start',
        render: ({ record: risk }) =>
          risk.resource || risk.attributes.hostDisplayName || risk.host,
      },
      {
        key: 'issueName',
        dataIndex: 'issueName',
        sortable: true,
        title: 'Issue',
        justify: 'flex-start',
      },
      {
        key: 'issueLevel',
        sortable: true,
        title: 'Risk Score',
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <RiskScoreRender
            display='flex'
            justifyContent='space-evenly'
            alignItems='center'
            textColor='monoBlack'
            fontWeight='bold'
            $riskColor={getRiskColor(risk.issueLevel)}
          >
            {risk.issueLevel.toFixed(1)}
          </RiskScoreRender>
        ),
      },
      {
        key: 'criticality',
        sortable: false,
        title: 'Asset Criticality',
        justify: 'flex-start',
        render: ({ record: { assetCriticality } }) => {
          return <CriticalityPill criticality={assetCriticality} />;
        },
      },
      {
        key: 'os',
        dataIndex: 'assetOS',
        sortable: false,
        title: 'OS',
        justify: 'flex-start',
      },
      {
        key: 'issueAction',
        dataIndex: 'issueAction',
        sortable: true,
        title: 'Action',
        justify: 'flex-start',
      },
      {
        key: 'assetCategory',
        dataIndex: 'assetCategory',
        sortable: false,
        title: 'Asset Category',
        justify: 'flex-start',
      },
      {
        key: 'state',
        dataIndex: 'state',
        sortable: true,
        title: 'State',
        justify: 'flex-start',
      },
      {
        key: 'status',
        title: 'Status',
        sortable: true,
        justify: 'flex-start',
        render: ({ record: risk }) => risk.attributes.status,
      },
      {
        key: 'statusReason',
        sortable: false,
        title: 'Status Reason',
        justify: 'flex-start',
        render: ({ record: risk }) => risk.attributes.statusReason || 'N/A',
      },
      {
        key: 'resolutionDate',
        title: 'Resolution Date',
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <>{getMetricsData(risk).resolutionDate}</>
        ),
      },
      {
        key: 'age',
        title: 'Age',
        justify: 'flex-start',
        render: ({ record: risk }) => <>{getMetricsData(risk).age}</>,
      },
      {
        key: 'daysToResolution',
        title: 'Days To Resolution',
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <>{getMetricsData(risk).daysToResolution}</>
        ),
      },
      {
        key: 'assetTags',
        title: 'Asset Tags',
        width: 10,
        render: ({ record: { assetTags } }) => (
          <TagPills tags={assetTags} tooltipPosition='left' />
        ),
      },
      {
        key: 'deploymentID',
        dataIndex: 'deploymentID',
        sortable: true,
        title: 'Deployment ID',
        justify: 'flex-start',
        render: ({ record: risk }) =>
          risk?.source === 'sensor' ? risk?.deploymentID : 'N/A',
      },
    ];

    return allColumns.filter(
      (column) => !hiddenColumnKeys.includes(column.key as string)
    );
  }, [hiddenColumnKeys, highlightList, riskArray, selectAll, selectRisk]);

  return (
    <StyledContainer data-testid='Risk-table'>
      {header}
      {selectedRisks}
      <Table
        data={riskArray}
        columns={columns}
        dataUniqueKey='id'
        onRow={{
          onClick: (e, risk) => {
            rowClick(risk.id);
          },
        }}
        onSort={onSort}
        emptyComponent={<div>No Data Available</div>}
        selectedRowKey={paneRiskId}
      />
      <Footer
        page={page || 0}
        setPage={setPage}
        pageSize={pageSize}
        totalItems={totalItems}
        highlightList={highlightList}
        selectAll={selectAll}
      />
    </StyledContainer>
  );
};

export default RiskTable;
