const FEATURE_KEY_PREFIX = 'ff_';

const STATE = {
  /**
   * features in beta are hidden by default, but users may opt-in
   */
  BETA: 'BETA',

  /**
   * features that are forcefully enabled / disabled
   */
  ON: 'ON',
  OFF: 'OFF',

  /**
   * feature flags that are no longer in use (not referenced in code)
   */
  RETIRED: 'RETIRED',
};

// Retired Feature flags
const ffIvaScannerConfigUp = 'iva_scanner_config_up';
const ffIVAScanControls = 'iva_scan_controls';
const ffEVAScanControls = 'eva_scan_controls';
const ffStandaloneEsxi = 'standalone_esxi';
const ffAprilFeatureRelease = 'iva_last_scan_agent_risk_rollup';
const ffNewScannerStates = 'new_scanner_states';
const ffAssetTagsImportExport = 'asset_tags_import_export';
const ffLastSuccessfulScanAgent = 'last_successful_scan_agent';
const ffLastSuccessfulScan = 'last_successful_scan';
const ffEvaSubdomain = 'eva_subdomain_enumeration';
const ffJulyFeatureRelease =
  'agent_last_successful_and_iva_scanner_status_enhancement_fix';
const ffMinRiskAssessment = 'min_risk_assessment';
const ffCustomerAssetBulkDelete = 'customer_asset_bulk_delete';
const ffAdsDeprecation = 'ads_field_deprecation';
const ffMarchRelease = '2024_march_release';
const ffAgentRemediationDetails = 'agent_remediation_details';
const ffLastSuccessfulScanEVA = 'last_successful_scan_eva';
const ffAssetCategoryRisk = 'asset_category_risk';
const ffLittleWinsMR20240206 = 'little_wins_mr_2024_02_06';
const ffAgentScanInsights = 'agent_scan_insights';
const ffLittleWinsMR20240305 = 'little_wins_mr_2024_03_05';
const ffIvaCredScanPartialUpdate = 'iva_cred_scan_partial_update';
const ffImproveTroubleshootingSettings = 'improve_troubleshooting_settings';
const ffHostToggleScan = 'host_toggle_scan';
const ffAssetCharts = 'asset_charts';
const ffLittleWinsMR20240319 = 'little_wins_mr_2024_03_19';
const ffLittleWinsMR20240402 = 'little_wins_mr_2024_04_02';
const ffLittleWinsMR20240416 = 'little_wins_mr_2024_04_16';
const ffUseSandbox = 'use_sandbox';
const ffMarchReleasev2 = '2024_march_release_v2';
const ffAssetsPartialReturnWarning = 'assets_partial_return_warning';
const ffLittleWinsMR20240528 = 'little_wins_mr_2024_05_28';
const ffLittleWinsMR20240611 = 'little_wins_mr_2024_06_11';
const ffIvaScannerNaming = 'iva_scanner_naming';
const ffBenchmarkRiskScore = 'benchmark_risk_score';

// Utility Features
export const ffFlagToggleWidget = 'flag_toggle_widget';
export const ffLocalRootsecure = 'local_rootsecure';

// Beta Features
export const ffAssetUiBeta = 'asset_ui_beta';

// Active Features
export const ffLittleWinsMR20240821 = 'little_wins_mr_2024_08_21';
export const ffLittleWinsMR20240918 = 'little_wins_mr_2024_09_18';
export const ffIvaScheduleLastScan = 'iva_schedule_last_scan';
export const ffLittleWinsMR273 = 'little_wins_mr_273';

// Coming Soon / Now Available
export const ffJulyReleaseV3 = '2024_july_release_v3';

// Features Shown in feature flag widget
export const FEATURE_FLAGS_KEYS = {
  ff_local_rootsecure: FEATURE_KEY_PREFIX + ffLocalRootsecure,
  ff_asset_ui_beta: FEATURE_KEY_PREFIX + ffAssetUiBeta,
  ff_little_wins_mr_273: FEATURE_KEY_PREFIX + ffLittleWinsMR273,
};

const FEATURE_STATES = {
  // Retired Feature flags
  [ffIvaScannerConfigUp]: STATE.RETIRED,
  [ffIVAScanControls]: STATE.RETIRED,
  [ffEVAScanControls]: STATE.RETIRED,
  [ffStandaloneEsxi]: STATE.RETIRED,
  [ffAprilFeatureRelease]: STATE.RETIRED,
  [ffNewScannerStates]: STATE.RETIRED,
  [ffAssetTagsImportExport]: STATE.RETIRED,
  [ffLastSuccessfulScanAgent]: STATE.RETIRED,
  [ffLastSuccessfulScan]: STATE.RETIRED,
  [ffEvaSubdomain]: STATE.RETIRED,
  [ffJulyFeatureRelease]: STATE.RETIRED,
  [ffMinRiskAssessment]: STATE.RETIRED,
  [ffCustomerAssetBulkDelete]: STATE.RETIRED,
  [ffAdsDeprecation]: STATE.RETIRED,
  [ffMarchRelease]: STATE.RETIRED,
  [ffAgentRemediationDetails]: STATE.RETIRED,
  [ffLastSuccessfulScanEVA]: STATE.RETIRED,
  [ffAssetCategoryRisk]: STATE.RETIRED,
  [ffLittleWinsMR20240206]: STATE.RETIRED,
  [ffAgentScanInsights]: STATE.RETIRED,
  [ffLittleWinsMR20240305]: STATE.RETIRED,
  [ffAssetCharts]: STATE.RETIRED,
  [ffIvaCredScanPartialUpdate]: STATE.RETIRED,
  [ffImproveTroubleshootingSettings]: STATE.RETIRED,
  [ffHostToggleScan]: STATE.RETIRED,
  [ffLittleWinsMR20240402]: STATE.RETIRED,
  [ffLittleWinsMR20240319]: STATE.RETIRED,
  [ffLittleWinsMR20240416]: STATE.RETIRED,
  [ffMarchReleasev2]: STATE.RETIRED,
  [ffAssetsPartialReturnWarning]: STATE.RETIRED,
  [ffLittleWinsMR20240528]: STATE.RETIRED,
  [ffLittleWinsMR20240611]: STATE.RETIRED,
  [ffIvaScannerNaming]: STATE.RETIRED,
  [ffBenchmarkRiskScore]: STATE.RETIRED,

  // Utility Features
  [ffUseSandbox]: STATE.BETA,
  [ffFlagToggleWidget]: STATE.BETA,
  [ffLocalRootsecure]: STATE.BETA,

  // Beta Features
  [ffAssetUiBeta]: STATE.BETA,

  // Feature on
  [ffLittleWinsMR20240821]: STATE.ON,
  [ffLittleWinsMR20240918]: STATE.ON,
  [ffIvaScheduleLastScan]: STATE.ON,
  [ffLittleWinsMR273]: STATE.ON,

  // Coming Soon / Now Available
  [ffJulyReleaseV3]: {
    date: '2024-07-16T00:00:00.000Z',
    comingSoonDisplay: true,
    nowAvailableDisplay: true,
    comingSoonText:
      'Arctic Wolf’s Managed Risk solution will be releasing two new features on July 10, 2024 - click here to find out more!',
    nowAvailableText:
      'Arctic Wolf’s Managed Risk released two new features on July 10 and 16, 2024 - click here to find out more!',
  },
};

/**
 * Gets the value of the specified feature flag directly from local storage
 * @param feature - flag key
 * @returns {string | null}
 */
export const getFeatureFlag = (feature) => {
  return localStorage.getItem(FEATURE_KEY_PREFIX + feature);
};

/**
 * Gets the value of the specified feature flag directly from local storage
 * @param feature - flag key
 * @returns {ComingSoonDef | null}
 */
// eslint-disable-next-line no-unused-vars
export const getComingSoon = (feature) => {
  return JSON.parse(localStorage.getItem(FEATURE_KEY_PREFIX + feature));
};

/**
 * Sets the value of the specified feature flag
 * @param feature - flag key
 * @param value {string} - new value for feature flag (typically 'true' or 'false')
 */
export const setFeatureFlag = (feature, value) => {
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  localStorage.setItem(FEATURE_KEY_PREFIX + feature, value);
};

/**
 * Returns true ONLY if the specified feature flag is set to 'true' in local storage
 * @param feature - flag key
 * @returns {boolean}
 */
export const isFeatureEnabled = (feature) => {
  return getFeatureFlag(feature) === 'true';
};

export const initFeatureFlagState = () => {
  for (const feature in FEATURE_STATES) {
    let state = FEATURE_STATES[feature];
    if (state === STATE.ON) {
      setFeatureFlag(feature, 'true');
    } else if (state === STATE.OFF) {
      setFeatureFlag(feature, 'false');
    } else if (state === STATE.RETIRED) {
      localStorage.removeItem(FEATURE_KEY_PREFIX + feature);
    } else {
      if (state.comingSoonDisplay && state.nowAvailableDisplay) {
        state = JSON.stringify(state);
      }

      setFeatureFlag(
        feature,
        getFeatureFlag(feature) || (state === STATE.BETA ? 'false' : state)
      );
    }
  }
};
