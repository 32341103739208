import React from 'react';
import {
  Button,
  Field,
  HStack,
  Primitive,
  Text,
  TextField,
} from '@rtkwlf/fenrir-react';

type ScoreSelection = Array<number>; // tuple `[from: number, to: number]` would be nicer , pending CRA upgrade

/**
 * https://www.first.org/cvss/specification-document#Qualitative-Severity-Rating-Scale
 */
const RATING_RANGES: Record<string, Array<number>> = {
  Low: [0.1, 3.9],
  Medium: [4.0, 6.9],
  High: [7.0, 8.9],
  Critical: [9.0, 10.0],
};

type RatingButtonProps = {
  title: string;
  selectedScores: ScoreSelection;
  range: ScoreSelection;
  defaultRange: Array<number>;
  onClick: (selection: ScoreSelection) => void;
};

export const RatingButton = ({
  range: [from, to],
  defaultRange,
  title,
  onClick,
  selectedScores: [currentFrom, currentTo],
}: RatingButtonProps) => {
  const isActive = currentFrom === from && currentTo === to;

  const toggleRatingButton = () => {
    if (isActive) {
      onClick(defaultRange);
    } else {
      onClick([from, to]);
    }
  };

  return (
    <Button
      margin='zero'
      variant={isActive ? 'primary' : 'secondary'}
      onClick={toggleRatingButton}
      className={isActive ? '__isActive' : ''}
    >
      {title}
    </Button>
  );
};

type RiskScoreFilterProps = {
  selectedScores: ScoreSelection;
  defaultRange: Array<number>;
  onScoreChange: (selection: ScoreSelection) => void;
};

export const RiskScoreFilter = ({
  selectedScores,
  onScoreChange,
  defaultRange,
}: RiskScoreFilterProps) => {
  const [currentFrom, currentTo] = selectedScores;

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newFrom = event.target.valueAsNumber;
    if (isNaN(newFrom) || newFrom <= 0) {
      newFrom = 0;
    }
    onScoreChange([newFrom, currentTo]);
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newTo = event.target.valueAsNumber;
    if (isNaN(newTo) || newTo > 10) {
      newTo = 10;
    }
    onScoreChange([currentFrom, newTo]);
  };

  return (
    <Primitive.div data-testid='risk-cvss'>
      <Field.Root>
        <Field.Label>Risk Score</Field.Label>
        <Field.Content>
          <HStack gap='small' maxWidth='30rem'>
            {Object.entries(RATING_RANGES).map(([title, range]) => (
              <RatingButton
                key={title}
                defaultRange={defaultRange}
                title={title}
                selectedScores={selectedScores}
                range={range}
                onClick={onScoreChange}
              />
            ))}
            <TextField
              margin='zero'
              type='number'
              value={currentFrom.toString()}
              onChange={handleFromChange}
            />
            <Text>to</Text>
            <TextField
              margin='zero'
              type='number'
              value={currentTo.toString()}
              onChange={handleToChange}
            />
          </HStack>
        </Field.Content>
      </Field.Root>
    </Primitive.div>
  );
};

export default RiskScoreFilter;
