/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import logo from '../../Images/logoWhite.png';
import logo2 from '../../Images/whiteIcon.png';
import { clickHandlers, legacyUpdateMenuLinks } from './utils';
import { getAnalyticsHost } from '../configReducer';
import { PortalMenu } from '../../Reusables/PortalMenu';

// Legacy Util
import { toggleMenu } from '../TopNav/utils';
import { IsAWNUser } from '../../utils/auth/auth';
import { escape } from 'lodash';
import { RSAuthorize } from '../../utils/auth/authorization';
import { RSAuthenticate } from '../../utils/auth/authentication';

const StyledLogo = styled.img<{ $isSmallIcon: boolean }>`
  max-height: ${(props) => (props?.$isSmallIcon ? '34px' : '46px')};
  max-width: 130px;
  margin-left: 8px;
  margin-top: 10px;
  position: relative;
`;

const StyledNavControlIcon = styled.i<{ $isSmallIcon: boolean }>`
  position: absolute;
  top: 20px;
  right: -12px;
  width: 24px;
  height: 24px;
  padding-left: ${(props) => (props?.$isSmallIcon ? '7' : '5')}px;
  padding-top: 4px;
  font-size: 16px;
  color: #23618e;
  background-color: white;
  border-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  :hover {
    box-shadow: 0 0 10px rgba(33, 33, 33, 0.5);
  }
`;

export type SidebarProps = {
  isSmallIcon: boolean;
  setIsSmallIcon: React.Dispatch<React.SetStateAction<boolean>>;
};

RSAuthenticate().then(() => {
  RSAuthorize();
});

const Sidebar = ({ isSmallIcon, setIsSmallIcon }: SidebarProps) => {
  useEffect(() => {
    clickHandlers();
    legacyUpdateMenuLinks();
  }, []);

  return (
    <div
      style={{ position: 'fixed', minHeight: '100%', top: '0' }}
      className='col-md-3 left_col cerberus-nav-width'
    >
      <div className='left_col scroll-view'>
        <div
          className='navbar nav_title cerberus-nav-width'
          style={{
            border: '0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <PortalMenu />
          <a href='/'>
            <StyledLogo
              $isSmallIcon={isSmallIcon}
              src={isSmallIcon ? logo2 : logo}
              alt='Arctic Wolf Logo'
              // className='side-logo'
            />
          </a>
          <StyledNavControlIcon
            data-testid='nav-control-icon'
            onClick={() => {
              setIsSmallIcon(!isSmallIcon);
              toggleMenu();
            }}
            $isSmallIcon={isSmallIcon}
            className={`fa fa-chevron-${isSmallIcon ? 'right' : 'left'}`}
          />
        </div>

        <div className='clearfix'></div>

        <br />

        <div
          id='sidebar-menu'
          className='main_menu_side hidden-print main_menu'
        >
          <div className='menu_section'>
            <ul className='nav side-menu'>
              <li>
                <a id='rs-index-link' href='/'>
                  <i className='fa fa-bar-chart'></i>Overview
                </a>
              </li>
              <li>
                <a id='rs-risk-view-link' href='/case'>
                  <i className='fa fa-briefcase'></i>Management Plan
                </a>
              </li>
              <li>
                <a id='rs-risk-view-link' href='/risks'>
                  <i className='fa fa-bug'></i>Risks
                </a>
              </li>
              <li>
                <a id='rs-asset-view-link' href='asset-view.html'>
                  <i className='fa fa-hdd-o'></i>Assets
                </a>
              </li>
              <li id='rs-scout-view-link'>
                <a id='rs-scout-risks-link' href='scout-risks.html'>
                  <i className='fa fa-binoculars'></i>Agent
                </a>
              </li>
              <li>
                <a id='rs-reach-view-link' href='reach.html'>
                  <i className='fa fa-globe' />
                  EVA
                </a>
              </li>
              {IsAWNUser() && (
                <li>
                  <a id='rs-asc-link' href='/attack-surface-coverage'>
                    <i className='fa fa-dot-circle-o'></i>Attack Surface
                    Coverage
                  </a>
                </li>
              )}
              <li>
                <a id='rs-config-link' href='/config'>
                  <i className='fa fa-wrench'></i>Scanner Config
                </a>
              </li>
              <li>
                <a id='rs-console-link' href='console.html'>
                  <i className='fa fa-list-ul'></i> Scanner Console{' '}
                </a>
              </li>
            </ul>
          </div>

          <div className='menu_section'>
            <h3>Tools</h3>
            <ul className='nav side-menu'>
              <li>
                <a>
                  <i className='fa fa-book'></i> Resources{' '}
                  <span className='fa fa-chevron-down'></span>
                </a>
                <ul className='nav child_menu'>
                  <li>
                    <a
                      href='https://docs.arcticwolf.com/csh?topicname=managed_risk_release_notes.html'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Release Notes
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://docs.arcticwolf.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Documents
                    </a>
                  </li>
                  <li>
                    <a href='support.html' target='_blank'>
                      Support
                    </a>
                  </li>
                </ul>
              </li>

              <li id='rsDownloadsMenu'>
                <a id='rs-downloads-link' href='landing.html'>
                  <i className='fa fa-download'></i>Downloads
                </a>
              </li>
              <li id='rsAnalyticsMenu'>
                <a
                  id='rs-analytics-link'
                  href={escape(getAnalyticsHost())}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fa fa-line-chart'></i>Analytics
                  <i className='fa fa-external-link rs_atics_link'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className='menu_section'>
          <h3 id='rs-admin-menu-title'>Admin</h3>
          <ul className='nav side-menu'>
            <li>
              <a id='rs-logout-link'>
                <i className='fa fa-sign-out'></i> Log Out{' '}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
